import React from 'react';
import { useTranslation } from 'react-i18next';

const Profile = () => {
    const { t } = useTranslation();

    const headingText = t('profile-heading').split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
    return (
        <section id="profile" className="max-w-screen-2xl w-full mx-auto flex flex-col items-start justify-center text-left my-32 md:my-48 px-8 md:px-16">
            <h2 className='mb-8 text-4xl'>{t('profile')}</h2>
            <hr className='border-white/10 w-full'></hr>
            <div className='mt-24 w-full flex flex-col'>
                <p className='text-4xl sm:text-5xl lg:text-6xl'>{headingText}</p>
            </div>
            <div className='px-6 bg-white rounded-full flex flex-row items-center mt-8 md:mt-6'>
                <svg width="24" height="24" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.31233 5.16671C3.28257 5.05135 3.22245 4.94608 3.13821 4.86184C3.05397 4.7776 2.94869 4.71747 2.83333 4.68771L0.788334 4.16038C0.753444 4.15047 0.722737 4.12946 0.700871 4.10052C0.679006 4.07159 0.667175 4.03631 0.667175 4.00004C0.667175 3.96377 0.679006 3.9285 0.700871 3.89956C0.722737 3.87063 0.753444 3.84961 0.788334 3.83971L2.83333 3.31204C2.94865 3.28231 3.0539 3.22223 3.13813 3.13806C3.22237 3.05388 3.28252 2.94867 3.31233 2.83338L3.83967 0.788376C3.84947 0.753348 3.87046 0.72249 3.89944 0.700507C3.92842 0.678525 3.96379 0.666626 4.00017 0.666626C4.03654 0.666626 4.07192 0.678525 4.10089 0.700507C4.12987 0.72249 4.15087 0.753348 4.16067 0.788376L4.68767 2.83338C4.71743 2.94873 4.77755 3.05401 4.86179 3.13825C4.94604 3.22249 5.05131 3.28262 5.16667 3.31238L7.21167 3.83938C7.24683 3.84908 7.27785 3.87005 7.29995 3.89907C7.32205 3.92809 7.33402 3.96356 7.33402 4.00004C7.33402 4.03652 7.32205 4.07199 7.29995 4.10102C7.27785 4.13004 7.24683 4.15101 7.21167 4.16071L5.16667 4.68771C5.05131 4.71747 4.94604 4.7776 4.86179 4.86184C4.77755 4.94608 4.71743 5.05135 4.68767 5.16671L4.16033 7.21171C4.15053 7.24674 4.12954 7.2776 4.10056 7.29958C4.07158 7.32156 4.03621 7.33346 3.99983 7.33346C3.96346 7.33346 3.92809 7.32156 3.89911 7.29958C3.87013 7.2776 3.84914 7.24674 3.83933 7.21171L3.31233 5.16671Z" fill="black" />
                </svg>
                <div className='w-18 h-16'>
                    <img src="profile_picture.webp" alt="Profile" className='object-contain w-full h-full' />
                </div>
                <svg width="24" height="24" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.31233 5.16671C3.28257 5.05135 3.22245 4.94608 3.13821 4.86184C3.05397 4.7776 2.94869 4.71747 2.83333 4.68771L0.788334 4.16038C0.753444 4.15047 0.722737 4.12946 0.700871 4.10052C0.679006 4.07159 0.667175 4.03631 0.667175 4.00004C0.667175 3.96377 0.679006 3.9285 0.700871 3.89956C0.722737 3.87063 0.753444 3.84961 0.788334 3.83971L2.83333 3.31204C2.94865 3.28231 3.0539 3.22223 3.13813 3.13806C3.22237 3.05388 3.28252 2.94867 3.31233 2.83338L3.83967 0.788376C3.84947 0.753348 3.87046 0.72249 3.89944 0.700507C3.92842 0.678525 3.96379 0.666626 4.00017 0.666626C4.03654 0.666626 4.07192 0.678525 4.10089 0.700507C4.12987 0.72249 4.15087 0.753348 4.16067 0.788376L4.68767 2.83338C4.71743 2.94873 4.77755 3.05401 4.86179 3.13825C4.94604 3.22249 5.05131 3.28262 5.16667 3.31238L7.21167 3.83938C7.24683 3.84908 7.27785 3.87005 7.29995 3.89907C7.32205 3.92809 7.33402 3.96356 7.33402 4.00004C7.33402 4.03652 7.32205 4.07199 7.29995 4.10102C7.27785 4.13004 7.24683 4.15101 7.21167 4.16071L5.16667 4.68771C5.05131 4.71747 4.94604 4.7776 4.86179 4.86184C4.77755 4.94608 4.71743 5.05135 4.68767 5.16671L4.16033 7.21171C4.15053 7.24674 4.12954 7.2776 4.10056 7.29958C4.07158 7.32156 4.03621 7.33346 3.99983 7.33346C3.96346 7.33346 3.92809 7.32156 3.89911 7.29958C3.87013 7.2776 3.84914 7.24674 3.83933 7.21171L3.31233 5.16671Z" fill="black" />
                </svg>
            </div>
            <div className='w-full flex flex-col items-end mt-8'>
                <p className="text-xl sm:text-2xl lg:text-3xl font-extralight max-w-2xl">{t('profile-text')}</p>
            </div>
        </section>
    );
}

export default Profile;
